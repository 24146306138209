<template lang="pug">
div.phoneNumberField.w-100
  label {{ $t(label) }}
  div.phone-fields.d-flex
    v-autocomplete(
      v-model="dialCode"
      :items="countries"
      :no-data-text="$t('notify.noData')"
      append-icon="mdi-chevron-down"
      item-text="value_abbr"
      item-value="dial_code"
      hide-details).phone-fields__select.w-20.pr-0.pl-0
      template(#item="data")
        span {{ data.item.value_abbr }}
        span.mx-3 {{ data.item.value }}
        span {{ data.item.dial_code }}
    v-text-field(
      v-mask="currentMask"
      v-model="phoneNumber"
      :prefix="dialCode"
      :placeholder="$t(label)"
      hide-details).w-80
  v-slide-y-transition
    div(v-if="errorMessages.length").v-messages.error--text.mt-1.px-3 {{ errorMessages[0] }}
</template>

<script>
import { mapState } from 'vuex'
import { FOR_CALIDATION_OTHER_COUNTRY_PHONE } from '@/configs/constants'
export default {
  props: {
    label: { type: String, default: 'phoneNumber' },
    errorMessages: { type: Array, default: () => ([]) },
    defaultValue: { type: String, default: '' },
    errorNumber: { type: Boolean, default: false }
  },
  data () {
    return {
      dialCode: '+380',
      phoneNumber: null
    }
  },
  computed: {
    ...mapState({
      countries: state => state.directory.country.filter(el => el.is_for_turbosms)
    }),
    currentMask () {
      return FOR_CALIDATION_OTHER_COUNTRY_PHONE.find(el => el.dial_code === this.dialCode)?.mask
    },
    compositeNumber () {
      let compositeNumber = ''
      if (this.dialCode) compositeNumber += this.dialCode
      if (this.phoneNumber) compositeNumber += this.phoneNumber.replace(/ /g, '')
      return compositeNumber
    }
  },
  methods: {
    fieldsAutocomplete (val) {
      this.dialCode = FOR_CALIDATION_OTHER_COUNTRY_PHONE.find(el => val.includes(el.dial_code)).dial_code
      this.phoneNumber = val.replace(this.dialCode, '')
    }
  },
  watch: {
    dialCode () { this.$emit('input', this.compositeNumber) },
    phoneNumber () {
      this.$emit('input', this.compositeNumber)
    },
    defaultValue (val) {
      if (val) this.fieldsAutocomplete(val)
    }
  },
  mounted () {
    if (this.defaultValue) this.fieldsAutocomplete(this.defaultValue || '+380')
  }
}
</script>

<style lang="sass">
  .phoneNumberField
    text-align: left

    .phone-fields
      // display: flex
      &__select
        width: 50px
</style>
